<script setup>
import { computed, defineProps, onMounted, onUnmounted } from "vue";
import * as log from "loglevel";
import _ from "lodash";
import { useStore } from "vuex-composition-helpers";
import { cmsTranslationByKey, objectAttributeInLanguage } from "../../mixins";
import { numberToStringFormat } from "@/utils";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  selectedParts: {
    type: Object,
    default: () => {
      return {
        "*1": {
          solrValue: "1",
        },
        "*2": {
          solrValue: "1",
        },
      };
    },
  },
});

const store = useStore();
const selectedLanguage = computed(() => store.getters["selectedLanguage"]);
const cmsTranslationData = computed(() => store.getters["cmsTranslationData"]);

const uniqueListMainDisciplinesLevel1 = computed(() => {
  return _.orderBy(
    _.uniqBy(
      props.data.map((d) => {
        return _.pick(d, [
          "List_MainDisciplineLevel1Id",
          "List_MainDisciplineLevel1_De",
          "List_MainDisciplineLevel1_Fr",
          "List_MainDisciplineLevel1_En",
          "List_MainDisciplineLevel1Number",
        ]);
      }),
      "List_MainDisciplineLevel1Id",
    ),
    "List_MainDisciplineLevel1Number",
  );
});

const displayData = computed(() => {
  return uniqueListMainDisciplinesLevel1.value.map((d) => {
    const uniqueChildIds = _.uniq(
      props.data
        .filter((p) => p.List_MainDisciplineLevel1Id === d.List_MainDisciplineLevel1Id)
        .map((p) => p.List_MainDisciplineLevel2Id),
    );

    const children = [];

    uniqueChildIds.forEach((childId) => {
      const entries = props.data.filter(
        (p) => p.List_MainDisciplineLevel2Id === childId,
      );
      const childEntry = _.pick(entries[0], [
        "List_MainDisciplineLevel2Id",
        "List_MainDisciplineLevel2_De",
        "List_MainDisciplineLevel2_Fr",
        "List_MainDisciplineLevel2_En",
        "List_MainDisciplineLevel2Number",
      ]);
      childEntry.sumGrants = _.sumBy(entries, "List_Grants");
      childEntry.sumGrantsSubmitted = _.sumBy(entries, "List_Grants_Submitted");
      childEntry.sumFunding = _.sum(
        entries.map((e) => {
          return parseInt(e.Funding || 0, 10);
        }),
      );
      childEntry.sumFundingSubmitted = _.sum(
        entries.map((e) => {
          return parseInt(e.Funding_Submitted || 0, 10);
        }),
      );

      let numberDataField = "sumGrants";
      const s1 = props.selectedParts["*1"].solrValue;
      const s2 = props.selectedParts["*2"].solrValue;

      if (s1 === "1" && s2 === "1") {
        numberDataField = "sumGrants";
      } else if (s1 === "2" && s2 === "1") {
        numberDataField = "sumGrantsSubmitted";
      } else if (s1 === "1" && s2 === "2") {
        numberDataField = "sumFunding";
      } else if (s1 === "2" && s2 === "2") {
        numberDataField = "sumFundingSubmitted";
      }
      childEntry.count = parseInt(childEntry[numberDataField] || 0, 10);
      children.push(childEntry);
    });

    return {
      ...d,
      children: _.orderBy(children, ["count"], ["desc"]),
    };
  });
});

const maxCount = computed(() => {
  return _.max(displayData.value.map((d) => _.maxBy(d.children, "count")?.count));
});

onMounted(() => {
  log.debug("DisciplinesMultiDomainData component mounted");
});

onUnmounted(() => {});
</script>

<template>
  <div>
    <h3>
      {{
        cmsTranslationByKey(
          cmsTranslationData,
          "MultiDomainListTitle",
          selectedLanguage,
        )
      }}
    </h3>

    <div style="margin-bottom: 16px">
      {{
        cmsTranslationByKey(
          cmsTranslationData,
          "MultiDomainListExplanation",
          selectedLanguage,
        )
      }}
    </div>

    <div
      v-for="domain in displayData"
      :key="domain.List_MainDisciplineLevel1Id"
      style="margin-bottom: 32px"
    >
      <h4>
        {{
          objectAttributeInLanguage(
            domain,
            "List_MainDisciplineLevel1",
            selectedLanguage,
          )
        }}
      </h4>

      <div class="discipline-row">
        <div class="discipline-row-discipline row-title">
          {{ cmsTranslationByKey(cmsTranslationData, "Discipline", selectedLanguage) }}
        </div>
        <div class="discipline-row-number row-title">
          <span v-if="selectedLanguage === 'fr'">
            {{ selectedParts["*2"].text }} {{ selectedParts["*1"].text }}
          </span>
          <span v-else>
            {{ selectedParts["*1"].text }} {{ selectedParts["*2"].text }}
          </span>
        </div>
      </div>
      <div
        v-for="subDomain in domain.children"
        :key="subDomain.List_MainDisciplineLevel2Id"
        class="discipline-row"
      >
        <div class="discipline-row-discipline">
          {{
            objectAttributeInLanguage(
              subDomain,
              "List_MainDisciplineLevel2",
              selectedLanguage,
            )
          }}
        </div>
        <div class="discipline-row-number">
          <div
            class="bar"
            :style="{ width: (100 * subDomain.count) / maxCount + '%' }"
          ></div>
          <div class="number">
            {{ numberToStringFormat(subDomain.count) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/css/colors";
@import "../../assets/css/mixins";
@import "../../assets/css/bulma_utils";



.row-title {
  font-weight: bold;
}

.discipline-row {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-top: 8px;

  @include mobile() {
    flex-direction: column;
    gap: 4px;
  }
}

.discipline-row:last-child {
  border-bottom: none;
}

.discipline-row-discipline {
  flex: 1 1 0;
}

.discipline-row-number {
  flex: 1 1 0;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;

  .bar {
    margin-top: -2px;
    height: 16px;
    background-color: $snf-blue-light;
  }

  .number {
    min-width: 100px;
  }
}
</style>
